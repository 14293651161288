import { useEffect, useState } from "react";
import TypingBubble from "./TypingBubble";
import { getTokenOrRefresh, SIM_CONFIGS } from "../utils";

const speechsdk = require("microsoft-cognitiveservices-speech-sdk");

const ChatOutput = ({
  simulation,
  azureToken,
  message: bot_message,
  onComplete,
  setAzureToken,
  setError, // <-- Receive setError as a prop
}) => {
  const [audioConfig, setAudioConfig] = useState();
  const [speechConfig, setSpeechConfig] = useState();
  const [isTyping, setIsTyping] = useState(
    bot_message === undefined || bot_message === ""
  );
  // const [isTyping, setIsTyping] = useState(true)
  const [botTalking, setBotTalking] = useState(true);
  const [player, updatePlayer] = useState({ p: undefined, muted: false });

  function generateSSML(simulation, textToSpeak) {
    console.log("generating SSML for simulation -> ", simulation);
    

    const getVoiceConfig = (simType) => {
      const config = SIM_CONFIGS[simType?.toUpperCase()] 
      console.log("config",config,simType)
      let ssml = `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="https://www.w3.org/2001/mstts" xml:lang="en-US">
        <voice name="${config.voice}">
          <mstts:express-as style="${config.style}" styledegree="${config.styleDegree}"${config.role ? ` role="${config.role}"` : ''}>
                <prosody volume="soft">
                  ${textToSpeak}
                </prosody>
              </mstts:express-as>
            </voice>
          </speak>`;

      return ssml;
    };

    return getVoiceConfig(simulation.simType);
  }

  useEffect(() => {
    const initializeSpeechConfig = async () => {
      try {
        const tokenObj = await getTokenOrRefresh();
        if (tokenObj.error) {
          throw new Error("Failed to refresh token");
        }
        setAzureToken(tokenObj);
        const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
          tokenObj.authToken,
          tokenObj.region
        );
        setSpeechConfig(speechConfig);
      } catch (error) {
        console.error("Error initializing speech config:", error);
        setError(true);
      }
    };

    initializeSpeechConfig();
  }, [setAzureToken]);

  useEffect(() => {
    console.log("isTyping... --> ", isTyping, bot_message);
    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
      azureToken.authToken,
      azureToken.region
    );
    const myPlayer = new speechsdk.SpeakerAudioDestination();

    myPlayer.onAudioEnd = function (_) {
      console.log("on audio end..");
      setBotTalking(false);
      onComplete(bot_message);
    };

    updatePlayer((p) => {
      p.p = myPlayer;
      return p;
    });
    const audioConfig = speechsdk.AudioConfig.fromSpeakerOutput(player.p);
    setAudioConfig(audioConfig);
    setSpeechConfig(speechConfig);
  }, []);

  async function talk() {
    setBotTalking(true);
    if (!speechConfig || !audioConfig) {
      console.log("speech and audio config", speechConfig, audioConfig);
      return;
    }

    let synthesizer = new speechsdk.SpeechSynthesizer(
      speechConfig,
      audioConfig
    );
    console.log("istyping...", isTyping, bot_message);
    if (isTyping || synthesizer === undefined) {
      return;
    }

    const textToSpeak = bot_message;
    const ssml = generateSSML(simulation, textToSpeak);
    console.log("ssml", ssml);
    console.log(`speaking text: ${textToSpeak}... with player: ${player.p}`);

    synthesizer.speakSsmlAsync(
      ssml,
      (result) => {
        let text;
        if (
          result.reason === speechsdk.ResultReason.SynthesizingAudioCompleted
        ) {
          text = `synthesis finished for "${textToSpeak}".\n`;
        } else if (result.reason === speechsdk.ResultReason.Canceled) {
          handleTokenRefresh();
        }
        synthesizer.close();
        synthesizer = undefined;
        console.log(text);
      },
      function (err) {
        console.log(`Error: ${err}.\n`);
        setError(true);
        handleTokenRefresh();
        synthesizer.close();
        synthesizer = undefined;
      }
    );
    setIsTyping(true);
  }

  useEffect(() => {
    if (!isTyping && speechConfig && audioConfig) {
      console.log("calling talk function");
      talk();
    }
  }, [isTyping, speechConfig, audioConfig]);
  const handleTokenRefresh = async () => {
    try {
      const newTokenObj = await getTokenOrRefresh();
      if (newTokenObj.error) {
        throw new Error("Failed to refresh token");
      }
      setAzureToken(newTokenObj);
      const newSpeechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
        newTokenObj.authToken,
        newTokenObj.region
      );
      setSpeechConfig(newSpeechConfig);
      console.log("Token refreshed successfully.");
      setError(false);
    } catch (error) {
      console.error("Error refreshing token:", error);
      setError(true);
    }
  };
  return (
    <div>
      {botTalking && (
        <div className="flex space-x-4 justify-center">
          {isTyping && <TypingBubble />}
        </div>
      )}
    </div>
  );
};

export default ChatOutput;
