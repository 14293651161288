import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./pages/Login";
import SimulationChat from "./pages/SimulationChat";
import Simulation from "./pages/Simulation";
import Transcript from "./pages/Transcript";
import SimulationHome from "./pages/SimulationHome";
import SSO from "./pages/SSO";

function App() {
  const chat = {
    status: "ACTIVE",
    timeOfChat: "2024-04-28T14:22:00.000Z",
    timePending: 15,
  };
  const CDN_URL = process.env.REACT_APP_CDN_URL;

  return (
    <React.Fragment>
      <div className=" w-full">
        <nav className="w-full h-16 flex px-11 justify-between items-center border-b-2 border-secondary sticky top-0 z-10 bg-navBackground">
          <img
            src={CDN_URL + "/assets/logo-white.png"}
            className="w-24 mr-28"
            alt="hale ai"
          />
        </nav>
        {/* <h1 className="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl text-center">
          <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400 ">
            Hale
          </span>
          AI.
          just some change to try deployment
        </h1> */}

        <div className="md:container md:mx-auto pt-8">
          <Router>
            <Routes>
              {/* <Route path="/login" element={<Login />} /> */}
              {/* <Route path="/sim" element={<ProtectedRoute />}> */}
              <Route path="/sim" element={<SimulationHome />} />
              <Route path="/sim/:sim_id" element={<Simulation />} />
              <Route
                path="/sim/:sim_id/:chat_id"
                element={<SimulationChat />}
              />
              <Route
                path="/sim/:sim_id/:chat_id/transcript"
                element={<Transcript />}
              />
              {/* </Route> */}
              <Route path="/sso" element={<SSO />} />
            </Routes>
          </Router>
        </div>
      </div>
    </React.Fragment>
  );
}

export default App;
