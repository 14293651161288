import { json } from "react-router-dom";

class MessageResponse {
  sender: string;
  content: string;
  time: Date;

  constructor(sender: string, content: string, time: Date) {
    this.sender = sender;
    this.content = content;
    this.time = time;
  }
}

class ChatResponse {
  chatId: string;
  stage: number;
  status: string;
  addedOn: Date;
  messages: MessageResponse[];
  stageHistory: any;

  constructor(
    chatId: string,
    stage: number,
    status: string,
    addedOn: Date,
    messages = [],
    stageHistory = {}
  ) {
    this.chatId = chatId;
    this.stage = stage;
    this.status = status;
    this.addedOn = addedOn;
    this.messages = messages;
    this.stageHistory = stageHistory;
  }

  addMessages(messages: MessageResponse[]) {
    this.messages.push(...messages);
  }
}

// TODO: This should capture sim_config_id as well.
class SimResponse {
  simulationId: string;
  simDetails: any;
  personaId: number;
  personaDetails: any;
  userId: string;
  status: string;
  stage: number;
  addedOn: Date;
  config: any;
  goals: any;
  isAssessment: boolean;
  simType: string;
  chatResponse: ChatResponse[];

  constructor(
    simulationId: string,
    simDetails: any,
    personaId: number,
    personaDetails: any,
    userId: string,
    status: string,
    stage: number,
    addedOn: Date,
    config: any,
    goals: any,
    isAssessment: boolean,
    simType: string,
    chatResponse: ChatResponse[] = []
  ) {
    this.simulationId = simulationId;
    this.stage = stage;
    this.simDetails = simDetails;
    this.personaId = personaId;
    this.personaDetails = personaDetails;
    this.userId = userId;
    this.status = status;
    this.addedOn = addedOn;
    this.config = config;
    this.chatResponse = chatResponse;
    this.goals = goals;
    this.isAssessment = isAssessment;
    this.simType = simType;
  }

  addChats(chats: ChatResponse[]) {
    this.chatResponse.push(...chats);
  }
}

function mapJsonToMessageResponse(jsonData: any) {
  return new MessageResponse(
    jsonData.sender,
    jsonData.content,
    new Date(jsonData.time)
  );
}

function mapJsonToChatResponse(jsonData: any) {
  // console.log(jsonData);
  const messages = jsonData.messages.map(mapJsonToMessageResponse);
  return new ChatResponse(
    jsonData.public_chat_id,
    jsonData.stage,
    jsonData.status,
    new Date(jsonData.added_on),
    messages,
    jsonData.stage_history
  );
}

function mapJsonToSimResponse(jsonData: any) {
  console.log("ssml",jsonData);
  const chatResponses = jsonData.chat_response.map(mapJsonToChatResponse);
  return new SimResponse(
    jsonData.public_sim_id,
    jsonData.sim_details,
    jsonData.persona_id,
    jsonData.persona_details,
    jsonData.user_id,
    jsonData.status,
    jsonData.stage,
    new Date(jsonData.added_on),
    jsonData.config,
    jsonData.goals,
    jsonData.is_assessment,
    jsonData.sim_type,
    chatResponses
  );
}

export {
  MessageResponse,
  ChatResponse,
  SimResponse,
  mapJsonToChatResponse,
  mapJsonToSimResponse,
  mapJsonToMessageResponse,
};
