import React, { useState, useEffect } from "react";
import { mapJsonToSimResponse } from "../models/Simulation";
import { fetchAuthenticatedUrl } from "../utils";

// refreshUrl={refreshUrl} stageHistory={simulation.chatResponse[0].stageHistory} currStage={simulation.chatResponse[0].stage
const Checklist = ({ refreshUrl, stageHistory, currStage, goals, recheckGoalStatus }) => {
  const [currentStage, setCurrentStage] = useState(currStage);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [parentList, setParentList] = useState(init(stageHistory));

  function init(stageHistory) {
    console.log("stageHistory: ", stageHistory)
    const parent_list = [
      { id: 1, text: "Stage 1", subObjectives: [] },
      { id: 2, text: "Stage 2", subObjectives: [] },
      { id: 3, text: "Stage 3", subObjectives: [] },
      { id: 4, text: "Stage 4", subObjectives: [] },
    ];

    Object.entries(stageHistory).map(([stage, objectives]) => {
      const currEntry = parent_list.find((p) => p.id === parseInt(stage));
      console.log("goal: ", goals)
      // console.log("objectives: ", objectives)
      // console.log("stage: ", stage)
      const currGoal = goals[stage];
      // console.log("currGoal: ", currGoal) 
      currEntry.subObjectives = Object.entries(objectives).map(
      
        ([text, isCompleted], index) => {
          // console.log("goal debug statement","text: ",text, "currGoal[text]: ", currGoal[text], currGoal)
          return(
         
          {
          id: index,
          text: currGoal[text],
          isCompleted:
            isCompleted === "yes" ||
            (typeof isCompleted == "boolean" && isCompleted), // Convert "yes" to true, or boolean
        })}
      );
      return currEntry;
    });
    //change this or return above to ensure that stage 3/4 are not visible
    return parent_list;
  }

  const fetchData = async () => {
    try {
      setIsRefreshing(true);
      const response = await fetchAuthenticatedUrl(refreshUrl);
      const sim = mapJsonToSimResponse(response.data);
      const newCurrStage = sim.chatResponse[0].stage;

      setParentList(init(sim.chatResponse[0].stageHistory));
      setCurrentStage(newCurrStage);
      // console.log(parentList);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    fetchData(); 
  }, [recheckGoalStatus]);

  // const [showSubList, setShowSubList] = useState(false);

  // const toggleSubList = () => {
  //   setShowSubList(!showSubList);
  // };

  function spinnerSVG() {
    return (
      <div className="absolute top-2 right-0">
        <svg
          className="animate-spin h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="rounded-lg relative">
        {isRefreshing && spinnerSVG()}

        <ul className="divide-y shadow rounded-xl bg-white">
          {parentList.map((objective) => (
            <li key={objective.id}>
              {/* check mark on stage */}
              <details className="group" open={objective.id <= currentStage}>
                <summary className="flex items-center gap-3 px-4 py-3 font-medium marker:content-none hover:cursor-pointer ">
                  <svg
                    className="w-5 h-5 text-gray-500 transition group-open:rotate-90"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                    ></path>
                  </svg>
                  <span>{objective.text || "Objective"}</span>
                </summary>
                <article className="px-4 pb-4">
                  {objective.id <= 4 && (
                    <ul className="list-none pl-2 mt-2">
                      {objective.subObjectives.length == 0 ? (
                        <span className="text-sm">Stage not yet started!</span>
                      ) : (
                        ""
                      )}
                      {objective.subObjectives.map((subObjective) => (
                        <li
                          className="flex items-center mb-1"
                          key={subObjective.id}
                        >
                          <span className="text-gray-400 mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              className="h-3 w-3"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </span>
                          <span
                            className={`
                        ${
                          subObjective.isCompleted
                            ? "text-green-600"
                            : "text-gray-800"
                        } text-sm`}
                          >
                            {subObjective.text}
                          </span>
                        </li>
                      ))}
                    </ul>
                  )}
                </article>
              </details>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default Checklist;
