import React, { useEffect, useRef } from "react";

const ChatHistory = ({ messages, personaImage }) => {
  //   const appendRandomMessages = () => {
  //     appendMessage('user', 'Hello there!');
  //     appendMessage('bot', 'Hi! How can I assist you today?');
  //     appendMessage('user', 'I need help with my order.');
  //     appendMessage('bot', 'Sure, please provide your order number.');
  //   };
  useEffect(() => {
    var objDiv = document.getElementById("messages");
    console.log("scrolling to bottom ", objDiv);
    objDiv.scrollTop = objDiv.scrollHeight;
  });
  return (
    <div className="grid space-y-4 m-2 flex-grow mt-8 ml-0">
      {messages.length === 0 ? (
        <>
          <div className="m-auto p-4 rounded-lg bg-gray-200 ">
            <p className="text-sm text-center">
              Start recording to initiate the conversation.
            </p>
          </div>
        </>
      ) : (
        messages.map((message, index) => (
          <div className={` max-w-[70%] flex ${
            message.sender === "U"
              ? "justify-self-end flex-row-reverse  "
              : "justify-self-start "
          }`} >
          <div className="min-w-[60px] flex flex-col justify-center p-1">
            {message.sender !== "U" && personaImage && (
              <img src={personaImage} alt="Persona" className="w-12 h-12 rounded-full object-cover border-2 border-indigo-200" />
            )}
          </div>
          <div
            key={index}
            className={`relative p-4 rounded-lg shadow-sm font-medium ${
              message.sender === "U"
                ? "justify-self-end bg-[#e4e7e7] message-user "
                : personaImage
                    ? "justify-self-start bg-[#c6cdf3] ml-[2px] text-[#686868] message-bot-with-image"
                    : "justify-self-start bg-[#c6cdf3] ml-[2px] text-[#686868] message-bot"
            }`}
          >
           
            {/* {console.log(message)} */}
            <p className="text-sm p-1">{message.content}</p>
            {/* <div className="flex justify-end">
              <p className="text-xs">{message.time.toLocaleDateString()}</p>
            </div> */}
          </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ChatHistory;
