import React, { useState, useRef, useEffect } from 'react';
import ReactHowler from 'react-howler';

const AudioPlayer = ({ src }) => {
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [seek, setSeek] = useState(0);
  const [volume, setVolume] = useState(1.0);
  const [isMuted, setIsMuted] = useState(false);
  const playerRef = useRef(null);
  const rafRef = useRef(null);

  useEffect(() => {
    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
    };
  }, []);

  const handleToggle = () => {
    setPlaying(!playing);
  };

  const handleOnLoad = () => {
    const soundDuration = playerRef.current.duration();
    setDuration(soundDuration);
  };

  const handleOnSeek = () => {
    setSeek(playerRef.current.seek());
  };

  const handleSeekChange = (e) => {
    const value = parseFloat(e.target.value);
    setSeek(value);
    playerRef.current.seek(value);
  };

  const handleVolumeChange = (e) => {
    const value = parseFloat(e.target.value);
    setVolume(value);
    setIsMuted(value === 0);
  };

  const toggleMute = () => {
    if (isMuted) {
      setVolume(1.0);
      setIsMuted(false);
    } else {
      setVolume(0);
      setIsMuted(true);
    }
  };

  const updateSeek = () => {
    setSeek(playerRef.current.seek());
    rafRef.current = requestAnimationFrame(updateSeek);
  };

  useEffect(() => {
    if (playing) {
      rafRef.current = requestAnimationFrame(updateSeek);
    } else {
      cancelAnimationFrame(rafRef.current);
    }
  }, [playing]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <div>
      <div className="bg-gray-100 rounded-lg shadow-md p-4">
        <ReactHowler
          src={src}
          format={["mp3"]}
          playing={playing}
          ref={playerRef}
          onLoad={handleOnLoad}
          onSeek={handleOnSeek}
          volume={volume}
        />
        <div className="flex items-center justify-between mb-4">
        <button 
            onClick={handleToggle}
            className="relative w-12 h-12 bg-blue-500 hover:bg-blue-600 text-white rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95"
          >
            <span className="absolute inset-0 flex items-center justify-center">
              {playing ? (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              ) : (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              )}
            </span>
          </button>
          <div id="formatTime" className="text-xs font-medium text-gray-600">
            {formatTime(seek)} / {formatTime(duration)}
          </div>
        </div>
        <div className="mb-4">
          <input
            type="range"
            min={0}
            max={duration}
            step={0.01}
            value={seek}
            onChange={handleSeekChange}
            className="w-full h-2 bg-gray-300 rounded-lg appearance-none cursor-pointer"
            style={{
              background: `linear-gradient(to right, #3B82F6 0%, #3B82F6 ${(seek / duration) * 100}%, #D1D5DB ${(seek / duration) * 100}%, #D1D5DB 100%)`
            }}
          />
        </div>
        <div className="flex justify-end">
          <button 
            onClick={toggleMute}
            className="mr-2 text-gray-600 hover:text-gray-800 focus:outline-none"
          >
            {isMuted ? (
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" clipRule="evenodd" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2" />
              </svg>
            ) : (
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
              </svg>
            )}
          </button>
          <input
            type="range"
            min={0}
            max={1}
            step={0.01}
            value={volume}
            onChange={handleVolumeChange}
            className="w-32 h-2 bg-gray-300 rounded-lg appearance-none cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;