import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { DEFAULT_PERSONAS } from "./Persona";
import { getTokenOrRefresh, postURL } from "../utils";
import ChatStatus from "./ChatStatus";
import ChatInput from "./ChatInput";
// import '../assets/styles/ChatDetails.css';
import ChatOutput from "./ChatOutput";
import ChatHistory from "./ChatHistory";
import { MessageResponse } from "../models/Simulation";
import { Dialog } from "@mui/material";
import { fetchAuthenticatedUrl } from "../utils";

const CHAT_OUTPUT = "o";
const CHAT_INPUT = "i";

function ChatDetails({ simulation, chat, personaId, refreshCheckList }) {
  const location = useLocation();
  const [persona, setPersona] = useState();
  const [error, setError] = useState(false);
  const [azureToken, setAzureToken] = useState(false);
  const [messages, setMessages] = useState(chat.messages);
  const [nextStage, setNextStage] = useState(CHAT_INPUT);
  const [userMessage, setUserMessage] = useState("");
  const [botMessage, setBotMessage] = useState("");
  const historyContainerRef = useRef(null); //used for auto-scroll
  const simId = localStorage.getItem("sim_id");
  const chatId = localStorage.getItem("chat_id");
  const [personaImage,setPersonaImage] = useState()

  const chatInputURL = `${process.env.REACT_APP_SIM_BACKEND_HOST}/sim/${
    simulation.simulationId || simId
  }/${chat.chatId || chatId}/msg`;

  const appendMessage = (sender, content) => {
    const newMessage = new MessageResponse(sender, content, new Date());
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };
  useEffect(() => {
    const fetchImgUrl = `${process.env.REACT_APP_SIM_BACKEND_HOST}/sim/${personaId}/image`;
    postURL(fetchImgUrl).then((resp) => {
      console.log(resp);
      setPersonaImage(resp.data);
    });
    console.log("persona image is ", personaImage);
  }, [personaId]);
  useEffect(() => {
    const setup = async () => {
      setPersona({
        ...DEFAULT_PERSONAS.find((p) => p.id === personaId),
        id: personaId,
      });
      // console.log("persona and chat session initiated successfully!");

      if (!azureToken) {
        const tokenObj = await azureRecorderSetup();
        // console.log("azure token is set!", tokenObj);
        setAzureToken(tokenObj);
      }
    };

    setup();
  }, [azureToken]);

  useEffect(() => {
    console.log("Setting up Azure token refresh interval...");
    const interval = setInterval(async () => {
      console.log("Refreshing Azure token...");
      try {
        const tokenObj = await azureRecorderSetup();
        setAzureToken(tokenObj);
      } catch (error) {
        console.error("Failed to refresh Azure token:", error);
        setError(true);
      }
    }, 60000); // Refresh every 1 minute

    return () => {
      console.log("Clearing Azure token refresh interval...");
      clearInterval(interval);
    };
  }, []);

  const azureRecorderSetup = async () => {
    let tokenObj = await getTokenOrRefresh();
    console.log("Token object:", tokenObj);
    if (tokenObj.error) {
      setError(true);
      throw new Error("Failed in setting up this chat!");
    }
    return tokenObj;
  };

  function chatInputComplete(user_message, bot_message) {
    appendMessage("U", user_message);
    // console.log(
    //   "chat input is complete...now show the output with message..",
    //   bot_message
    // );
    setNextStage(CHAT_OUTPUT);
    setBotMessage(bot_message);
  }

  function chatOutputComplete(bot_message) {
    appendMessage("B", bot_message);
    refreshCheckList();
    // console.log("chat output is now complete..", bot_message);
    setNextStage(CHAT_INPUT);
    setBotMessage("");
  }

  useEffect(() => {
    // This effect will be triggered whenever nextStage or botMessage changes
    // console.log("Component should re-render now", nextStage, botMessage);
  }, [nextStage, botMessage]);

  //This method helps in scrolling to the bottom of the div
  const setHistoryContainerRef = (element) => {
    if (element) {
      historyContainerRef.current = element;
      historyContainerRef.current.scrollTop =
        historyContainerRef.current.scrollHeight;
    }
  };

  return (
    <div className="container h-[80vh] overflow-hidden flex flex-col">
      {simulation && azureToken.authToken && (
        <>
          <div className="grid grid-cols-1 gap-4">
            {/* <div>
                <Persona person={persona} />
            </div> */}
            <div>
              <ChatStatus simulation={simulation} chat={chat} personaImage={personaImage} />
            </div>
          </div>
          <div
            ref={setHistoryContainerRef}
            className="flex-1 overflow-y-auto flex flex-col h-screen justify-between items-center"
          >
            <div id="messages" className="w-full h-fit overflow-y-auto mb-10">
              <ChatHistory messages={messages} personaImage={personaImage} />
              <div></div>
              <div>
                {nextStage === CHAT_OUTPUT && (
                  <ChatOutput
                    simulation={simulation}
                    azureToken={azureToken}
                    message={botMessage}
                    onComplete={chatOutputComplete}
                    setAzureToken={setAzureToken}
                    setError={setError}
                  />
                )}
              </div>
            </div>
            <div className="sticky bottom-0 rounded-lg w-fit">
              <div className="max-w-md mx-auto">
                {nextStage === CHAT_INPUT && (
                  <ChatInput
                    url={chatInputURL}
                    azureToken={azureToken}
                    onComplete={chatInputComplete}
                    setAzureToken={setAzureToken}
                    setError={setError}
                    refreshCheckList={refreshCheckList}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {!location.state && !azureToken.authToken && (
        <div>Unable to start this chat! Redirecting in 5 seconds...</div>
      )}

      { 
        error && (
          <Dialog open={true} onClose={() => window.location.reload()}>
            <div className="p-4">
              <p>Refresh this page to continue from where you left off.</p>
              <button
                className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => window.location.reload()}
              >
                Refresh
              </button>
            </div>
          </Dialog>
        )
      }
    </div>
  );
}

export default ChatDetails;
