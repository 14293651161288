import React, { useState, useEffect } from "react";

const TypingBubble = () => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots.length === 3) {
          return "";
        } else {
          return prevDots + ".";
        }
      });
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="flex items-center ">
      <div className="rounded-lg">
        <span className="text-blue-500">Processing{dots}</span>
      </div>
    </div>
  );
};

export default TypingBubble;
