import React, { useState } from "react";
import { HelpCircle, X } from "lucide-react";

const HelpIconModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <button
        onClick={() => setIsOpen(true)}
        className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
        aria-label="Help"
      >
        <HelpCircle className="w-6 h-6 text-gray-600" />
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full ">
            <div className="flex justify-between items-center p-4 border-b ">
              <h2 className="text-xl font-semibold">Need Help?</h2>
              <button
                onClick={() => setIsOpen(false)}
                className="text-gray-500 hover:text-gray-700"
                aria-label="Close"
              >
                <X className="w-6 h-6" />
              </button>
            </div>
            <div className="p-4">
              <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4">
                <p className="font-bold">Contact Information</p>
                <p>
                  If anything gets stuck just hit refresh. For any help, please
                  reach out to darsh@gethale.ai
                </p>
              </div>
            </div>
            <div className="flex justify-end p-4 border-t">
              <button
                onClick={() => setIsOpen(false)}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HelpIconModal;
